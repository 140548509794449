$(document).ready(function () {
  setTimeout(function () {
    $('.js-registration-field').val('');
  }, 0);

  $('.panel-heading').addClass('collapsed');

  $('#register-as-consumer').on('click', function () {
    $('.js-user-role-consumer').attr('checked', true);
  });

  $('#register-as-provider').on('click', function () {
    $('.js-user-role-provider').attr('checked', true);
  });

  if (typeof window.orientation !== 'undefined') {
    let wantToKnowMoreSection = $('.want-to-know-more-area');
    if (wantToKnowMoreSection.length > 0) {
      wantToKnowMoreSection.hide();
    }
  }

  if ($('.home.redesigned-landing-page').length > 0) {
    $('html').css('overflow-x', 'hidden');
    $('body').css('overflow-x', 'hidden');
  } else {
    $('html').css('overflow-x', 'initial');
    $('body').css('overflow-x', 'initial');
  }
});
